import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import FormComponentSelector from "../FormComponents/FormComponentSelector"

class CheatsheetQuestions extends React.Component {
  state = {
    loading: false,
    done: false,
    cheatsheetQuestion: this.props.cheatsheetQuestion,
    fieldErrors: {}
  };

  render() {
    const {
      loading,
      fieldErrors,
      cheatsheetQuestion
    } = this.state;

    return (
      <div className="col-md-12">
        <div className="clmmis-form-container">
          <FormComponentSelector
            fieldKey={"title"}
            fieldName={"title"}
            fieldLabel={"Title"}
            fieldRequired={true}
            fieldValue={cheatsheetQuestion.title}
            fieldType={"text"}
            fieldErrors={fieldErrors}
            useStateToggle={false}
            enabled={true}
            onChange={this.handlerChangeField}
          />
          <FormComponentSelector
            fieldKey={"heading"}
            fieldName={"heading"}
            fieldLabel={"Heading"}
            fieldRequired={true}
            fieldValue={cheatsheetQuestion.heading}
            fieldType={"text"}
            fieldErrors={fieldErrors}
            useStateToggle={false}
            enabled={true}
            onChange={this.handlerChangeField}
          />
          <FormComponentSelector
            fieldKey={"order"}
            fieldName={"order"}
            fieldLabel={"Order"}
            fieldRequired={true}
            fieldValue={cheatsheetQuestion.order || this.props.nextOrderNumber}
            fieldType={"select"}
            fieldErrors={fieldErrors}
            fieldSelectOptions={this.props.availableOrderNumbers}
            fieldSkipSelect={true}
            useStateToggle={false}
            enabled={true}
            onChange={this.handlerChangeField}
          />
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="clmmis-form-container">
              <div className="form-group pre-scrollable" id="contingency-checkboxes">
                <h3>Weaken</h3>
                {this.props.contingencies.filter(contingency => contingency.is_develop == false).map(contingency => (
                  <FormComponentSelector
                    key={`conginency-${contingency.id}`}
                    fieldKey={contingency.id}
                    fieldName={"contingency_ids"}
                    fieldLabel={contingency.title_en}
                    fieldRequired={false}
                    fieldValue={this.cheatsheetQuestionAssociatedWith(contingency)}
                    fieldType={"checkbox"}
                    fieldErrors={fieldErrors}
                    useStateToggle={false}
                    enabled={true}
                    onChange={this.handlerChangeField}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="clmmis-form-container">
              <div className="form-group pre-scrollable" id="contingency-checkboxes">
              <h3>Develop</h3>
              {this.props.contingencies.filter(contingency => contingency.is_develop == true).map(contingency => (
                <FormComponentSelector
                  key={`conginency-${contingency.id}`}
                  fieldKey={contingency.id}
                  fieldName={"contingency_ids"}
                  fieldLabel={contingency.title_en}
                  fieldRequired={false}
                  fieldValue={this.cheatsheetQuestionAssociatedWith(contingency)}
                  fieldType={"checkbox"}
                  fieldErrors={fieldErrors}
                  useStateToggle={false}
                  enabled={true}
                  onChange={this.handlerChangeField}
                />
              ))}
              </div>
            </div>
          </div>
        </div>

        <div className="clmmis-form-container">
          <form onSubmit={this.submit}>
            <a className="btn btn-default" href="/cheatsheet_questions">Back</a>
            <button
              className="btn btn-primary pull-right"
              type="submit"
              name="commit"
            >
              {this.props.formType == "edit" ? (
                <span>Update Cheatsheet Question</span>
              ) : (
                <span>Create Cheatsheet Question</span>
              )}

            </button>
          </form>
        </div>
      </div>
    );
  }

  cheatsheetQuestionAssociatedWith = (contingency) => {
    var contingencyIds = this.state.cheatsheetQuestion.contingency_ids || [];
    return contingencyIds.includes(contingency.id);
  }

  toggleContigencyId = (newValue, checked) => {
    var currentContingencyIds = this.state.cheatsheetQuestion.contingency_ids || [];
    if (newValue == true) {
      currentContingencyIds.push(checked);
    } else {
      currentContingencyIds = currentContingencyIds.filter(id => id !== checked)
    }

    return currentContingencyIds;
  }

  handlerChangeField = (newValue, key, checked=null) => {
    const cheatsheetQuestion = this.state.cheatsheetQuestion;

    if (key == "contingency_ids") {
      cheatsheetQuestion[key] = this.toggleContigencyId(newValue, checked);
    } else {
      cheatsheetQuestion[key] = newValue;
    }

    this.setState({ cheatsheetQuestion: cheatsheetQuestion, fieldErrors: {} });
  };

  processRailsErrors = (errorFields) => {
    const fieldErrors = this.state.fieldErrors;
    Object.keys(errorFields).forEach(errorFieldKey => {
      fieldErrors[errorFieldKey] = errorFields[errorFieldKey];
    });
    this.setState({ fieldErrors: fieldErrors });
  };

  buildFormData = () => {
    const formData = new FormData();
    var cheatsheetQuestion = this.state.cheatsheetQuestion;

    Object.keys(cheatsheetQuestion).forEach(key => {
      if (key == "contingency_ids") {
        cheatsheetQuestion[key].forEach(contingencyId => {
          formData.append(`${this.props.formName}[${key}][]`, contingencyId);
        });
      } else {
        formData.append(`${this.props.formName}[${key}]`, cheatsheetQuestion[key]);
      }
    });

    return formData;
  };

  submit = e => {
    e.preventDefault();

    let that = this;

    if (this.state.loading) {
      return;
    }

    const formData = this.buildFormData();

    this.setState({ loading: true });

    let config = {
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
    }

    const request = this.props.formType == "edit"
      ? axios.put(
          this.props.formUrl,
          formData,
          config
        )
      : axios.post(
          this.props.formUrl,
          formData,
          config
        );


    request
      .then(request => {
        this.setState({ done: true, loading: false });

        setTimeout(() => {
          window.location.href = this.props.redirectUrl;
        }, 700);
      })
      .catch(function(error) {
        that.processRailsErrors(error.response.data);
        that.setState({ loading: false, done: false });
      });
  };
}

CheatsheetQuestions.propTypes = {
  formType: PropTypes.string,
  formName: PropTypes.string,
  formUrl: PropTypes.string
};

export default CheatsheetQuestions;
