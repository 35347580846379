import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import FormComponentSelector from "../FormComponents/FormComponentSelector"

class ByLessonNumber extends React.Component {
  state = {
    selectedLessonNumber: this.props.current_lesson_id,
    fieldErrors: {}
  };

  render() {
    const {
      fieldErrors,
      selectedLessonNumber
    } = this.state;

    const {
      lesson_number_select,
      lesson_number_repertoire_select,
      current_lesson_id,
      pwdsAdded
    } = this.props;

    return (
      <div>
        <div className="row">
          <div className="col-md-10">
            <FormComponentSelector
              fieldKey={"selectedLessonNumberId"}
              fieldName={"selectedLessonNumberId"}
              fieldLabel={"Lesson Number"}
              fieldRequired={false}
              fieldValue={selectedLessonNumber}
              fieldType={"select"}
              fieldErrors={fieldErrors}
              fieldSelectOptions={lesson_number_select}
              jsonKey={"lesson_number"}
              fieldSkipSelect={true}
              useStateToggle={false}
              enabled={true}
              onChange={this.handlerChangeSelectedLessonNumber}
            />
          </div>
          <div className="col-md-2">
            <button
              className="btn btn-primary pull-right add-all"
              onClick={() => this.addAllLessons()}
            >
              Add All
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="list-group list-group-light pwd-lesson-select-list">
              {this.filteredLessonNumberRepertoireSelect().map((lesson, index) => (
                !(index % 2) ? (
                  <li className="list-group-item" key={index}>
                    <div className="repertoire-dropdown">
                      {lesson.repertoire_dropdown}
                      {this.lessonNotAdded(lesson) && (
                        <button
                          className="btn btn-primary btn-xs pull-right glyphicon glyphicon-plus white-glyphicon"
                          title="Add"
                          onClick={() => this.props.addPwdByLesson(lesson)}
                        >
                        </button>
                      )}
                    </div>
                  </li>
                ) : null
              ))}
            </div>
          </div>
          <div className="col-md-6">
            <div className="list-group list-group-light pwd-lesson-select-list">
              {this.filteredLessonNumberRepertoireSelect().map((lesson, index) => (
                (index % 2) ? (
                  <li className="list-group-item" key={index}>
                    <div className="repertoire-dropdown">
                      {lesson.repertoire_dropdown}
                      {this.lessonNotAdded(lesson) && (
                        <button
                          className="btn btn-primary btn-xs pull-right glyphicon glyphicon-plus white-glyphicon"
                          title="Add"
                          onClick={() => this.props.addPwdByLesson(lesson)}
                        >
                        </button>
                      )}
                    </div>
                  </li>
                ) : null
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }

  lessonNotAdded = (lesson) => {
    return !this.props.pwdsAdded.map(p => p.lessonId).includes(lesson.id);
  };

  handlerChangeSelectedLessonNumber = (newValue) => {
    this.setState({ selectedLessonNumber: newValue });
  };

  filteredLessonNumberRepertoireSelect = (lessonNumber) => {
    const selectedLessonNumberId = (lessonNumber) ? lessonNumber : this.state.selectedLessonNumber
    if (selectedLessonNumberId) {
      return this.props.lesson_number_repertoire_select.filter(
        repertoire => repertoire.lesson_number_id == selectedLessonNumberId
      )
    }
  }

  addAllLessons = () => {
    const lessons = this.props.lesson_number_repertoire_select.filter(
      repertoire => repertoire.lesson_number_id == this.state.selectedLessonNumber
    )
    this.props.addAllLessonsForLessonNumber(lessons);
  }
}

ByLessonNumber.propTypes = {
  current_lesson_id: PropTypes.number,
  lesson_number_select: PropTypes.array,
  lesson_number_repertoire_select: PropTypes.array
};

export default ByLessonNumber;
