import React from "react";
import PropTypes from "prop-types";
import Markdown from "react-remarkable";
import FormCheckBox from "./FormCheckBox";
import FormDateTime from "./FormDateTime";
import FormDescription from "./FormDescription";
import FormField from "./FormField";
import FormHidden from "./FormHidden";
import FormRadioButtons from "./FormRadioButtons";
import FormSelect from "./FormSelect";
import FormTextArea from "./FormTextArea";
import FormToggle from "./FormToggle";
import FormUsers from "./FormUsers";

const checkAndConvertSelectOptions = (selectOptions, jsonKey) => {
  if (selectOptions instanceof Array) {
    if (selectOptions[0] instanceof Array) {
      return selectOptions;
    } else if (selectOptions[0] instanceof Object) {
      return Object.values(selectOptions).map(so => [so[jsonKey], so.id]);
    } else {
      return selectOptions.map(so => [so, so])
    }
  } else if (selectOptions instanceof Object) {
    return Object.values(selectOptions).map(so => [so.name, so.id]);
  } else if (selectOptions != undefined) {
    return selectOptions.split(",").map(so => [so.trim(), so.trim()]);
  } else {
    return ["", ""];
  }
};

const FormComponentSelector = props => {
  const fieldEnabled = props.useStateToggle ? props.stateEnabled : props.enabled;
  if (fieldEnabled) {
    switch (props.fieldType) {
      case 'text':
      case 'number':
      case 'email':
        return (
          <FormField
            key={props.fieldKey}
            name={props.fieldName}
            label={props.fieldLabel}
            required={props.fieldRequired}
            value={props.fieldValue}
            type={props.fieldType}
            fieldErrors={props.fieldErrors[props.fieldKey]}
            step={props.fieldStep}
            min={props.fieldMin}
            max={props.fieldMax}
            placeholder={props.fieldPlaceholder}
            onChange={e => props.onChange(e.target.value, props.fieldKey)}
          />
        );
      case 'textarea':
        return (
          <FormTextArea
            key={props.fieldKey}
            name={props.fieldKey}
            label={props.fieldLabel}
            required={props.fieldRequired}
            value={props.fieldValue == "null" ? "" : props.fieldValue}
            type={props.fieldType}
            placeholder={props.fieldPlaceholder}
            errors={props.fieldErrors[props.fieldKey]}
            onChange={e => props.onChange(e.target.value, props.index, "note")}
          />
        );
      case 'date':
      case 'time':
      case 'datetime':
        return (
          <FormDateTime
            key={props.fieldKey}
            name={props.fieldKey}
            label={props.fieldLabel}
            required={props.fieldRequired}
            value={props.fieldValue}
            type={props.fieldType}
            fieldErrors={props.fieldErrors[props.fieldKey]}
            onChange={e => props.onChange(e.sender.value(), props.fieldKey)}
          />
        );
      case 'checkbox':
        return (
          <FormCheckBox
            key={props.fieldKey}
            value={props.fieldKey}
            name={props.fieldName}
            label={props.fieldLabel}
            required={props.fieldRequired}
            checked={props.fieldValue === 'true' || props.fieldValue === true}
            errors={props.fieldErrors[props.fieldKey]}
            onChange={e => props.onChange(e.target.checked, props.fieldName, props.fieldKey)}
          />
        );
      case 'radiobutton':
        return (
          <FormRadioButtons
            key={props.fieldKey}
            label={props.fieldLabel}
            required={props.fieldRequired}
            items={checkAndConvertSelectOptions(props.fieldSelectOptions, props.jsonKey)}
            value={props.fieldValue || null}
            displayReset={props.displayReset || false}
            errors={props.fieldErrors[props.fieldKey]}
            onChange={e => props.onChange(e.target.value, props.fieldKey)}
          />
        );
      case 'select':
        return (
          <FormSelect
            key={props.fieldKey}
            name={props.fieldKey}
            label={props.fieldLabel}
            required={props.fieldRequired}
            value={props.fieldValue}
            fieldErrors={props.fieldErrors[props.fieldKey]}
            options={checkAndConvertSelectOptions(props.fieldSelectOptions, props.jsonKey)}
            skipSelect={props.fieldSkipSelect}
            onChange={e => props.onChange(e.target.value, props.fieldKey)}
          />
        );
      case 'hidden':
        return (
          <FormHidden
            key={props.fieldKey}
            name={props.fieldKey}
            value={props.fieldValue}
          />
        );
      case 'toggle':
        return (
          <FormToggle
            key={props.fieldKey}
            labelTrue={props.fieldLabelTrue}
            labelFalse={props.fieldLabelFalse}
            required={props.fieldRequired}
            value={props.fieldValue || null}
            errors={props.fieldErrors[props.fieldKey]}
            onChange={e => props.onChange(e, props.index, "completed")}
          />
        );
      case 'users':
        return (
          <FormUsers
            key={props.fieldKey}
            required={props.fieldRequired}
            value={props.fieldValue}
            fieldErrors={props.fieldErrors[props.fieldKey] || []}
            onChange={props.onChange}
            label={props.fieldLabel}
            associationName={props.fieldAssociationName}
          />
        );
      case 'description':
        return (
          <FormDescription
            description={props.fieldDescription}
          />
        )
      default:
        return null;
    }
  } else {
    return null;
  }
}

FormComponentSelector.propType = {
  fieldErrors: PropTypes.array,
  required: PropTypes.bool,
  onChange: PropTypes.func
};

export default FormComponentSelector;
