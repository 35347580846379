import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import FormComponentSelector from "../FormComponents/FormComponentSelector"

class ByRepertoire extends React.Component {
  state = {
    fieldErrors: {},
    repertoireId: null,
    repertoireTrackId: null,
    lessonId: null,
    label: null,
    typeOfBehavior: null,
    lessonOverview: null,
    pwdsToAdd: []
  };

  componentDidMount() {
    this.setDefaults();
  };

  setDefaults = () => {
    const repertoire = this.props.develop_weaken_repertoire_options[0];
    const repertoireId = repertoire.id;
    const repertoireTrackId = this.filteredRepertoireTracks(repertoireId)[0].id;
    const lesson = this.filteredLessonSelect(repertoireId, repertoireTrackId)[0];
    const lessonId = lesson.id;
    const lessonOverview = lesson.translated_task;

    this.setState({
      lesson: lesson,
      lessonId: lessonId,
      repertoireId: repertoireId,
      repertoireTrackId: repertoireTrackId,
      lessonOverview: lessonOverview,
      fieldErrors: {}
    });
  };

  render() {
    const {
      fieldErrors,
      repertoireId,
      repertoireTrackId,
      lessonId,
      label,
      typeOfBehavior,
      pwdsToAdd,
      lessonOverview
    } = this.state;

    const {
      current_lesson_id,
      develop_weaken_repertoire_options,
      repertoire_tracks,
      lessons_select
    } = this.props;

    return (
      <div>
        <FormComponentSelector
          fieldKey={"repertoireId"}
          fieldName={"repertoireId"}
          fieldLabel={"To Develop or Weaken"}
          fieldRequired={false}
          fieldValue={repertoireId}
          fieldType={"select"}
          fieldErrors={fieldErrors}
          fieldSelectOptions={develop_weaken_repertoire_options}
          jsonKey={"title"}
          fieldSkipSelect={true}
          useStateToggle={false}
          enabled={true}
          onChange={this.handlerChangeRepertoireId}
        />

        <FormComponentSelector
          fieldKey={"repertoireTrackId"}
          fieldName={"repertoireTrackId"}
          fieldLabel={"Track"}
          fieldRequired={false}
          fieldValue={repertoireTrackId}
          fieldType={"select"}
          fieldErrors={fieldErrors}
          fieldSelectOptions={this.filteredRepertoireTracks()}
          jsonKey={"track"}
          fieldSkipSelect={true}
          useStateToggle={false}
          enabled={true}
          onChange={this.handlerChangeRepertoireTrackId}
        />

        <FormComponentSelector
          fieldKey={"lessonId"}
          fieldName={"lessonId"}
          fieldLabel={"Lesson"}
          fieldRequired={false}
          fieldValue={lessonId}
          fieldType={"select"}
          fieldErrors={fieldErrors}
          fieldSelectOptions={this.filteredLessonSelect()}
          jsonKey={"lesson_num"}
          fieldSkipSelect={true}
          useStateToggle={false}
          enabled={true}
          onChange={this.handlerChangeLessonId}
        />

        { lessonOverview &&
          <div>
            <p dangerouslySetInnerHTML={{__html: lessonOverview}}></p>
          </div>
        }

        {lessonId == "No Lesson" ? (
            <div>
              <FormComponentSelector
                fieldKey={"typeOfBehavior"}
                fieldName={"typeOfBehavior"}
                fieldLabel={"Type of Behavior"}
                fieldRequired={false}
                fieldValue={typeOfBehavior}
                fieldType={"text"}
                fieldErrors={fieldErrors}
                fieldSkipSelect={true}
                useStateToggle={false}
                enabled={true}
                onChange={this.handlerChangeTypeOfBehavior}
              />
              <p>NOTE: all text fields will be blank initially unless you associate this summary with a lesson using the selector above.</p>
            </div>
          ) : (
            <FormComponentSelector
              fieldKey={"label"}
              fieldName={"label"}
              fieldLabel={"Label"}
              fieldRequired={false}
              fieldValue={label}
              fieldType={"text"}
              fieldErrors={fieldErrors}
              fieldSkipSelect={true}
              useStateToggle={false}
              enabled={true}
              onChange={this.handlerChangeLabel}
            />
          )
        }
        <div className="clearfix">
          <button
            className="btn btn-primary pull-right"
            onClick={() => this.addPwd()}
          >
            Add PwD
          </button>
        </div>
      </div>
    )
  }

  handlerChangeRepertoireId = (repertoireId) => {
    const repertoireTrackId = this.filteredRepertoireTracks(repertoireId)[0].id;
    const lesson = this.filteredLessonSelect(repertoireId, repertoireTrackId)[0]

    this.setState({
      repertoireId: repertoireId,
      repertoireTrackId: repertoireTrackId,
      lessonId: lesson.id,
      lessonOverview: lesson.translated_task,
      label: null,
      typeOfBehavior: null,
      fieldErrors: {}
    });
  };

  handlerChangeRepertoireTrackId = (repertoireTrackId) => {
    const lesson = this.filteredLessonSelect(null, repertoireTrackId)[0];

    this.setState({
      repertoireTrackId: repertoireTrackId,
      lessonId: lesson.id,
      lessonOverview: lesson.translated_task,
      label: null,
      typeOfBehavior: null,
      fieldErrors: {}
    });
  };

  handlerChangeLessonId = (lessonId) => {
    let lessonOverview = this.state.lessonOverview;

    if (lessonId != "No Lesson") {
      const lesson = this.lessonById(lessonId);
      lessonOverview = lesson.translated_task;
    } else {
      lessonOverview = "";
    }

    this.setState({
      lessonId: lessonId,
      lessonOverview: lessonOverview,
      label: null,
      typeOfBehavior: null,
      fieldErrors: {}
    });
  };

  handlerChangeLabel = (label) => {
    this.setState({ label: label });
  };

  handlerChangeTypeOfBehavior = (typeOfBehavior) => {
    this.setState({ typeOfBehavior: typeOfBehavior });
  };

  filteredRepertoireTracks = (repertoireId) => {
    const parsedRepertoireId = (repertoireId) ? parseInt(repertoireId) : parseInt(this.state.repertoireId);
    return this.props.repertoire_tracks.filter(
      repertoireTrack => repertoireTrack.repertoire_ids.includes(parsedRepertoireId)
    )
  }

  filteredLessonSelect = (repertoireId, repertoireTrackId) => {
    const parsedRepertoireId = repertoireId ? parseInt(repertoireId) : parseInt(this.state.repertoireId);
    const parsedRepertoireTrackId = repertoireTrackId ? parseInt(repertoireTrackId) : parseInt(this.state.repertoireTrackId);
    var filteredLessonSelect = this.props.lessons_select.filter(
      lesson => lesson.repertoire_id == parsedRepertoireId && lesson.repertoire_track_id == parsedRepertoireTrackId
    )
    // filteredLessonSelect.push({id: "No Lesson", lesson_num: "No Lesson"})
    return filteredLessonSelect
  }

  lessonById = (lessonId) => {
    var lesson = this.props.lessons_select.filter(
      lesson => lesson.id == lessonId
    )[0]
    return lesson
  }

  repertoireById = (repertoireId) => {
    var repertoire = this.props.develop_weaken_repertoire_options.filter(
      repertoire => repertoire.id == repertoireId
    )[0]
    return repertoire
  }

  repertoireTrackById = (repertoireTrackId) => {
    var repertoireTrack = this.props.repertoire_tracks.filter(
      repertoireTrack => repertoireTrack.id == repertoireTrackId
    )[0]
    return repertoireTrack
  }

  isNoLessonPwd = () => {
    return this.state.lessonId == "No Lesson";
  }

  addPwd = () => {
    const noLesson = this.isNoLessonPwd();
    const repertoire = this.repertoireById(this.state.repertoireId);
    const repertoireTrack = this.repertoireTrackById(this.state.repertoireTrackId);
    const lesson = noLesson ? null : this.lessonById(this.state.lessonId);

    this.props.addPwdByRepertoire(
      noLesson,
      lesson,
      repertoire,
      repertoireTrack,
      this.state.label,
      this.state.typeOfBehavior
    )
  };

}

ByRepertoire.propTypes = {
  current_lesson_id: PropTypes.number,
  develop_weaken_repertoire_options: PropTypes.array,
  repertoire_tracks: PropTypes.array,
  lessons_select: PropTypes.array
};

export default ByRepertoire;
