import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const FormRadioButtons = ({
  type,
  label,
  name,
  value,
  displayReset,
  errors,
  items,
  required,
  onChange
}) => (
  <div
    className={classnames("form-group", {
      "has-error": errors && errors.length > 0
    })}
  >
    <label className="control-label word-wrap" htmlFor={`input-${name}`}>
      {label}
      {required ? " *" : ""}
    </label>

    <div>
      {items.map(item => (
        <div key={item[0]}>
          <input
            id={`radio-${name}-${item[0]}`}
            type="radio"
            name={name}
            value={item[0]}
            style={{ width: "auto", marginRight: 10 }}
            checked={item[0] === value}
            onChange={onChange}
          />
          <label htmlFor={`radio-${name}-${item[0]}`}>{item[1]}</label>
        </div>
      ))}
      {errors ? <span className="help-block">{errors.join(", ")}</span> : null}

      {value && displayReset ? (
        <div>
          <button href="#" className="btn btn-indigo btn-raised btn-long" name={name} onClick={onChange}>
            Reset
          </button>
        </div>
      ) : null}
    </div>
  </div>
);

FormRadioButtons.propType = {
  label: PropTypes.string,
  name: PropTypes.string,
  errors: PropTypes.array,
  value: PropTypes.string,
  items: PropTypes.array,
  required: PropTypes.bool,
  onChange: PropTypes.func
};

FormRadioButtons.defaultProps = {
  required: false
};

export default FormRadioButtons;
