import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import FormComponentSelector from "../FormComponents/FormComponentSelector"
import ByLessonNumber from "./ByLessonNumber"
import ByRepertoire from "./ByRepertoire"
import ByGoal from "./ByGoal"

class ProgressSelect extends React.Component {
  state = {
    loading: false,
    fieldErrors: {},
    pwdsToAdd: [],
    typeOfAdd: "Lesson Number"
  };

  defaultPwdValues = () => {
    return {
      pwdKey: "",
      pwdAdditionType: "",
      lessonId: null,
      repertoireId: null,
      label: "",
      typeOfBehavior: "",
      toDevelopOrWeaken: "",
      goalType: "",
      progressType: "",
      isUndesirable: 0,
      overview: ""
    }
  };

  render() {
    const {
      loading,
      fieldErrors,
      pwdsToAdd,
      typeOfAdd
    } = this.state;

    const {
      current_lesson_id,
      lesson_number_select,
      lesson_number_repertoire_select,
      develop_weaken_repertoire_options,
      develop_weaken_goal_options,
      repertoire_tracks,
      lessons_select
    } = this.props;

    return (
      <div>
        <div className="clmmis-form-container">
          <FormComponentSelector
            fieldKey={"typeOfAdd"}
            fieldName={"typeOfAdd"}
            fieldLabel={"Find format (lesson) by"}
            fieldRequired={false}
            fieldValue={typeOfAdd}
            fieldType={"radiobutton"}
            fieldErrors={fieldErrors}
            fieldSelectOptions={[["Lesson Number", "Lesson Number"], ["Repertoire", "Repertoire"], ["Goal", "Goal"]]}
            fieldSkipSelect={true}
            useStateToggle={false}
            enabled={true}
            onChange={this.toggleTypeOfAdd}
          />

          {(() => {
            switch (typeOfAdd) {
              case "Lesson Number":
                return <ByLessonNumber
                  lesson_number_select={lesson_number_select}
                  lesson_number_repertoire_select={lesson_number_repertoire_select}
                  pwdsAdded={this.state.pwdsToAdd}
                  addPwdByLesson={this.addPwdByLesson}
                  removePwd={this.removePwd}
                  addAllLessonsForLessonNumber={this.addAllLessonsForLessonNumber}
                  current_lesson_id={current_lesson_id}
                />
              case "Repertoire":
                return <ByRepertoire
                  current_lesson_id={current_lesson_id}
                  lesson_number_repertoire_select={lesson_number_repertoire_select}
                  develop_weaken_repertoire_options={develop_weaken_repertoire_options}
                  repertoire_tracks={repertoire_tracks}
                  lessons_select={lessons_select}
                  addPwdByRepertoire={this.addPwdByRepertoire}
                />
              case "Goal":
                return <ByGoal
                  develop_weaken_goal_options={develop_weaken_goal_options}
                  addPwdByGoal={this.addPwdByGoal}
                />
              default:
                return null
            }
          })()}
        </div>

        {pwdsToAdd.length > 0 &&
          <div className="clearfix">
            <table className="table clmmis-table" id="new-pwds-table">
              <thead>
                <tr>
                  <td colSpan="2">PwDs to Create</td>
                </tr>
              </thead>
              <tbody>
                {pwdsToAdd.map((pwd, index) => (
                  <tr key={pwd.pwdKey}>
                    <td>{pwd.overview}</td>
                    <td>
                      <a className="delete-pwd" href="#">
                        <span
                          className="glyphicon glyphicon-trash"
                          title="<%= t('buttons.delete') %>"
                          onClick={() => this.removePwd(pwd)}
                        >
                        </span>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        }

        <div className="clmmis-form-container">
          <form onSubmit={this.submit}>
            <a className="btn btn-default" href="/cheatsheet_questions">Back</a>
            {(pwdsToAdd.length > 0 && loading == false) &&
              <button
                className="btn btn-primary pull-right"
                type="submit"
                name="commit"
              >
                <span>Create All</span>
              </button>
            }
          </form>
        </div>
      </div>
    );
  }

  toggleTypeOfAdd = (typeOfAdd) => {
    this.setState({ typeOfAdd: typeOfAdd, fieldErrors: {} });
  };

  removePwd = (pwdToRemove) => {
    const pwdsToAdd = this.state.pwdsToAdd.filter(pwdAdded => pwdAdded.pwdKey != pwdToRemove.pwdKey);
    this.setState({ pwdsToAdd: pwdsToAdd});
  };

  addPwdByLesson = (lesson) => {
    const pwdsToAdd = this.state.pwdsToAdd;
    var pwdParams = this.defaultPwdValues();
    pwdParams.pwdKey = `${lesson.id}${Date.now()}`
    pwdParams.lessonId = lesson.id;
    pwdParams.pwdAdditionType = "by_lesson";
    pwdParams.overview = lesson.repertoire_dropdown
    pwdsToAdd.push(pwdParams);
    this.setState({ pwdsToAdd: pwdsToAdd });
  };

  addAllLessonsForLessonNumber = (lessons) => {
    var newPwds = [];
    lessons.forEach(lesson => {
      var pwdParams = this.defaultPwdValues();
      pwdParams.pwdKey = `${lesson.id}${Date.now()}`
      pwdParams.lessonId = lesson.id;
      pwdParams.pwdAdditionType = "by_lesson";
      pwdParams.overview = lesson.repertoire_dropdown;
      newPwds.push(pwdParams);
    });
    this.setState({
      pwdsToAdd: [...this.state.pwdsToAdd, ...newPwds]
    })
  };

  addPwdByRepertoire = (noLesson, lesson, repertoire, repertoireTrack, label, typeOfBehavior) => {
    const pwdsToAdd = this.state.pwdsToAdd;
    var pwdParams = this.defaultPwdValues();
    if (noLesson) {
      pwdParams.pwdKey = `${repertoire.id}${Date.now()}`
      pwdParams.pwdAdditionType = "by_repertoire_no_lesson";
      pwdParams.overview = `No Lesson - ${repertoire.title} (${repertoireTrack.track})`
      pwdParams.repertoireId = repertoire.id;
      pwdParams.label = label ? label : "";
      pwdParams.typeOfBehavior = typeOfBehavior ? typeOfBehavior : "";
    } else {
      pwdParams.pwdKey = `${lesson.id}${Date.now()}`
      pwdParams.lessonId = lesson.id;
      pwdParams.pwdAdditionType = "by_repertoire";
      pwdParams.overview = lesson.repertoire_dropdown
      pwdParams.repertoireId = repertoire.id;
      pwdParams.label = label ? label : "";
      pwdParams.typeOfBehavior = typeOfBehavior ? typeOfBehavior : "";
    };

    pwdsToAdd.push(pwdParams);
    this.setState({ pwdsToAdd: pwdsToAdd });
  };

  addPwdByGoal = (toDevelopOrWeaken, goalType, typeOfBehavior, progressType) => {
    const pwdsToAdd = this.state.pwdsToAdd;
    var pwdParams = this.defaultPwdValues();
    pwdParams.pwdKey = `${toDevelopOrWeaken}${Date.now()}`
    pwdParams.pwdAdditionType = "by_goal";
    pwdParams.overview = `To Develop or Weaken - ${toDevelopOrWeaken} - ${goalType}`
    pwdParams.goalType = goalType ? goalType : "";
    pwdParams.progressType = progressType ? progressType : "";
    pwdParams.typeOfBehavior = typeOfBehavior ? typeOfBehavior : "";
    pwdParams.toDevelopOrWeaken = toDevelopOrWeaken ? toDevelopOrWeaken : "";

    if(toDevelopOrWeaken == "Undesirable") {
      pwdParams.isUndesirable = true;
    };

    pwdsToAdd.push(pwdParams);
    this.setState({ pwdsToAdd: pwdsToAdd });
  };

  buildFormData = () => {
    const formData = new FormData();
    var pwdsToAdd = this.state.pwdsToAdd;
    pwdsToAdd.forEach(pwd => {
      formData.append(`[progresses][][pwdAdditionType]`, pwd['pwdAdditionType']);
      formData.append(`[progresses][][lessonId]`, pwd['lessonId']);
      formData.append(`[progresses][][repertoireId]`, pwd['repertoireId']);
      formData.append(`[progresses][][label]`, pwd['label']);
      formData.append(`[progresses][][typeOfBehavior]`, pwd['typeOfBehavior']);
      formData.append(`[progresses][][toDevelopOrWeaken]`, pwd['toDevelopOrWeaken']);
      formData.append(`[progresses][][goalType]`, pwd['goalType']);
      formData.append(`[progresses][][progressType]`, pwd['progressType']);
      formData.append(`[progresses][][isUndesirable]`, pwd['isUndesirable']);
    });

    return formData;
  };

  submit = e => {
    e.preventDefault();

    let that = this;

    if (this.state.loading) {
      return;
    }

    const formData = this.buildFormData();

    this.setState({ loading: true });

    let config = {
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
    }

    const request = axios.post(
      this.props.form_url,
      formData,
      config
    )

    request
      .then(request => {
        setTimeout(() => {
          window.location.href = this.props.redirect_url;
        }, 500);
      })
      .catch(function(error) {
        setTimeout(() => {
          window.location.href = this.props.redirect_url;
        }, 500);
      });
  };
}

ProgressSelect.propTypes = {
  current_lesson_id: PropTypes.number,
  lesson_number_select: PropTypes.array,
  lesson_number_repertoire_select: PropTypes.array,
  develop_weaken_repertoire_options: PropTypes.array,
  develop_weaken_goal_options: PropTypes.array,
  repertoire_tracks: PropTypes.array,
  lessons_select: PropTypes.array,
  learner_id: PropTypes.number,
  redirect_url: PropTypes.string,
  formUrl: PropTypes.string
};

export default ProgressSelect;
