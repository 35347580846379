import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import FormComponentSelector from "../FormComponents/FormComponentSelector"

class ByGoal extends React.Component {
  state = {
    fieldErrors: {},
    toDevelopOrWeaken: "Undesirable",
    typeLabel: "Undesirable Type",
    goalType: "Injurious",
    typeOfBehavior: null,
    progressType: null
  };

  render() {
    const {
      fieldErrors,
      toDevelopOrWeaken,
      typeLabel,
      goalType,
      typeOfBehavior,
      progressType
    } = this.state;

    const {
      develop_weaken_goal_options
    } = this.props;

    return (
      <div>
        <FormComponentSelector
          fieldKey={"toDevelopOrWeaken"}
          fieldName={"toDevelopOrWeaken"}
          fieldLabel={"To Develop or Weaken"}
          fieldRequired={false}
          fieldValue={toDevelopOrWeaken}
          fieldType={"select"}
          fieldErrors={fieldErrors}
          fieldSelectOptions={develop_weaken_goal_options}
          jsonKey={"title"}
          fieldSkipSelect={true}
          useStateToggle={false}
          enabled={true}
          onChange={this.handlerChangeDevelopOrWeaken}
        />

        <FormComponentSelector
          fieldKey={"goalType"}
          fieldName={"goalType"}
          fieldLabel={typeLabel}
          fieldRequired={false}
          fieldValue={goalType}
          fieldType={"select"}
          fieldErrors={fieldErrors}
          fieldSelectOptions={this.goalTypeOptions()}
          jsonKey={"track"}
          fieldSkipSelect={true}
          useStateToggle={false}
          enabled={true}
          onChange={this.handlerChangeGoalType}
        />

        {toDevelopOrWeaken == "Undesirable" &&
          <FormComponentSelector
            fieldKey={"typeOfBehavior"}
            fieldName={"typeOfBehavior"}
            fieldLabel={"Type of Behavior"}
            fieldRequired={false}
            fieldValue={typeOfBehavior}
            fieldType={"text"}
            fieldErrors={fieldErrors}
            fieldSkipSelect={true}
            useStateToggle={false}
            enabled={true}
            onChange={this.handlerChangeTypeOfBehavior}
          />
        }

        <FormComponentSelector
          fieldKey={"progressType"}
          fieldName={"progressType"}
          fieldLabel={"Label?"}
          fieldRequired={false}
          fieldValue={progressType}
          fieldType={"text"}
          fieldErrors={fieldErrors}
          fieldSkipSelect={true}
          useStateToggle={false}
          enabled={true}
          onChange={this.handlerChangeProgressType}
        />

        <div className="clearfix">
          <button
            className="btn btn-primary pull-right"
            onClick={() => this.addPwd()}
          >
            Add PwD
          </button>
        </div>
      </div>
    )
  }

  handlerChangeDevelopOrWeaken = (toDevelopOrWeaken) => {
    this.setState({
      toDevelopOrWeaken: toDevelopOrWeaken,
      typeLabel: `${toDevelopOrWeaken} Type`,
      goalType: this.goalTypeOptions()[0],
      typeOfBehavior: null,
      progressType: null
     });
  };

  handlerChangeGoalType = (goalType) => {
    this.setState({
      goalType: goalType,
      typeOfBehavior: null,
      progressType: null
    });
  };

  handlerChangeTypeOfBehavior = (typeOfBehavior) => {
    this.setState({ typeOfBehavior: typeOfBehavior });
  };

  handlerChangeProgressType = (progressType) => {
    this.setState({ progressType: progressType });
  };

  goalTypeOptions = () => {
    switch(this.state.toDevelopOrWeaken) {
      case 'Undesirable':
        return ['Injurious', 'Self-injurious', 'Property Violations', 'Non-Compliance (NOT Participating)', 'Annoying']
      case 'Tool':
        return ['Hand', 'Power', 'Reference', 'Objects']
      case 'Subject Matter':
        return ['Performing Arts', 'Visual Arts', 'Movement', 'Math', 'Science', 'Health', 'Language Arts', 'Social Studies']
      case 'Functional Action':
        return ['Nourish', 'Communicate', 'Entertain', 'Socialize', 'Purchase', 'Maintain Health', 'Maintain Setting', 'Transport', 'Earn a Living']
      default:
        return ['Error'];
    }
  }

  addPwd = () => {
    this.props.addPwdByGoal(
      this.state.toDevelopOrWeaken,
      this.state.goalType,
      this.state.typeOfBehavior,
      this.state.progressType
    )
  };

}

ByGoal.propTypes = {
  develop_weaken_goal_options: PropTypes.array
};

export default ByGoal;
